<template>
    <div class="login-main">
        <div class="login-content">
            <div class="login-left">
                <div class="login-left-main">
                    <div class="login-headline">
                        <h3>{{webName}}</h3>
                    </div>
                    <div class="login-form-main">
                        <el-form ref="form" :model="formdata" :rules="rules">
                            <el-form-item prop="username">
                                <el-input v-model="formdata.username" class="form-input-item" placeholder="欢迎登录校园小邮局！" prefix-icon="el-icon-user" @keydown.enter.native="submitForm('form')" clearable></el-input>
                            </el-form-item>
                            <el-form-item prop="password">
                                <el-input v-model="formdata.password" class="form-input-item" placeholder="密码" prefix-icon="el-icon-lock" @keydown.enter.n.native="submitForm('form')" show-password clearable></el-input>
                            </el-form-item>
                            <el-form-item class="form-btn-item">
                                <el-button size="medium" class="submit-btn" @click="submitForm('form')">登录</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>

            </div>
            <div class="login-right">

            </div>
        </div>

    </div>
</template>

<script>
// import {get, post} from "../utils/request";
import { login, commonInfoAppName } from '../config/apis'
export default {
    name: "Login",
    data() {
        return {
            webName: '',
            formdata: {
                username: '',
                password: ''
            },
            rules: {
                username: [
                    {required: true, message: '请输入用户名', trigger: 'blur'},
                    {min: 3, max: 20, message: '用户名在3到20个字符', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                    {min: 6, max: 20, message: '密码长度在6到20个字符', trigger: 'blur'}
                ]
            }
        }
    },
    mounted() {
        this.getWebInfo()
    },
    methods: {
        getWebInfo() {
            commonInfoAppName().then(res => {
                this.webName = res.data.name
            }).catch(err => {})
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let formData = new FormData()
                    formData.append('username', this.formdata.username)
                    formData.append('password', this.formdata.password)
                    login(formData).then(res => {
                        localStorage.setItem('role', res.data.role_id)
                        localStorage.setItem('id', res.data.id)
                        localStorage.setItem('username', res.data.username)
                        localStorage.setItem('nickname', res.data.nickname)
                        localStorage.setItem('avatar', res.data.avatar)
                        localStorage.setItem('domain_url', res.data.domain_url)
                        if (res.data.role_id === 1) {
                            localStorage.setItem('superToken', res.data.token)
                            this.$message.success(res.msg)
                            this.$router.push('/super/index')
                        } else if (res.data.role_id === 2) {
                            localStorage.setItem('schoolToken', res.data.token)
                            this.$message.success(res.msg)
                            this.$router.push('/school/index')
                        }
                    }).catch(err => {})
                } else {
                    return false;
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .login-main {
        width: 100%;
        height: 100vh;
        background-color: #F3F3F6;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .login-content {
        height: 579px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .login-left {
            width: 445px;
            height: 100%;
            background-color: #FFF;
            padding: 0px 5px 0px 60px;
            .login-left-main {
                .login-headline {
                    width: 100%;
                    height: 215px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h3 {
                        font-size: 24px;
                        color: #03A293;
                        font-weight: 400;
                    }
                }
                .login-form-main {
                    width: 100%;
                    .form-input-item {
                        ::v-deep input {
                            background-color: #F4F9F9;
                            &:focus {
                                border-color: #03A293;
                            }
                        }
                    }
                    .form-btn-item {
                        margin-top: 42px;
                        .submit-btn {
                            width: 100%;
                            background-color: #03A293;
                            color: #FFFFFF;
                            &:active {
                                border-color: #03A293;
                            }
                        }
                    }
                }
            }
        }
        .login-right {
            background: url("../assets/images/bg.png");
            width: 608px;
            height: 100%;
        }
    }


</style>